import React from 'react'

import { COLOR } from '@farewill/ui/tokens'
import { PRODUCTS } from 'lib/products/constants'

import DefaultLayout from 'layouts/DefaultLayout'

import Content from './Content'

// TODO: Get title and layout description from content/SEO team
const title = 'Arrange a simple, personal direct cremation'
const layoutDescription =
  'Arrange a simple, personal direct cremation with the UK’s trusted funeral specialists'

const SimpleCoreLocal = (): React.ReactElement => (
  <DefaultLayout
    title={title}
    description={layoutDescription}
    product={PRODUCTS.FUNERAL}
    headerColor={COLOR.WHITE}
    headerType="compact"
    footerType="compact"
    footerShowFuneralPlansLegalInformation
    meta={[
      {
        name: 'robots',
        content: 'noindex,follow',
      },
    ]}
  >
    <Content />
  </DefaultLayout>
)

export default SimpleCoreLocal
